<template>
  <div class="blog-detail-feed-body">
    <div class="feed-contents quillWrapper" v-if="blog">
      <div class="d-flex align-center">
        <div
          class="mt-5 app-bold-font"
          :class="$vuetify.breakpoint.xs ? 'font-20' : 'font-24'"
        >
          {{ this.blog.title }}
        </div>
        <v-spacer />
        <v-tooltip bottom v-if="blog.poster == profile._id">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
              small
              v-bind="attrs"
              v-on="on"
              @click.stop="onEditBlog"
              class="mr-2"
            >
              <v-icon color="#6038F7" style="margin-top: 2px;" size="18">
                mdi-pencil
              </v-icon>
            </v-btn>
          </template>
          Edit Blog
        </v-tooltip>
        <v-tooltip bottom v-if="blog.poster == profile._id">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              style="border-radius: 1px; border-color: #6038F7; border-style: solid; border-radius: 36px;"
              small
              v-bind="attrs"
              v-on="on"
              @click.stop="onDeleteBlog"
            >
              <v-icon color="#6038F7" style="margin-top: 2px;" size="18">
                mdi-delete
              </v-icon>
            </v-btn>
          </template>
          Delete Blog
        </v-tooltip>
      </div>
      <div class="d-flex flex-row align-center mt-3 mb-6">
        <editable-avatar
          :image="blog.poster_image"
          :editable="false"
          :size="40"
        />
        <div
          class="d-flex w-100"
          :class="
            $vuetify.breakpoint.xs
              ? 'flex-column justify-center'
              : 'flex-row align-center'
          "
        >
          <div
            class="ml-3 url-button"
            style="font-size: 16px; font-weight: bold;"
            @click.stop="goToAgencyProfile()"
            @mousedown.stop
            @touchstart.stop
          >
            {{ blog.poster_name }}
          </div>
          <v-spacer />
          <div class="ml-3 font-14">
            Posted on:
            {{
              new Date(this.blog.createdAt).toLocaleString("en-us", {
                dateStyle: "long",
              })
            }}
          </div>
        </div>
      </div>
      <v-img :src="this.blog.cover" alt="" width="100%" />
      <div class="post-content ql-editor mt-5" v-html="this.blog.html"></div>
    </div>
    <v-progress-circular indeterminate color="amber" v-else class="mt-6" />
    <confirm-delete-dialog
      :deleteDialog="deleteDialog"
      :loading="loading"
      :onClose="onCloseDialog"
      :onConfirm="onConfirmDelete"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.bubble.css";
import EditableAvatar from "@/components/EditableAvatar.vue";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
export default {
  components: { EditableAvatar, ConfirmDeleteDialog },
  name: "BlogDetails",
  data() {
    return {
      blog: null,
      deleteDialog: false,
      loading: false,
    };
  },
  methods: {
    ...mapActions("blog", {
      getBlogDetails: "getBlogDetails",
      deleteBlog: "deleteBlog",
    }),
    onEditBlog() {
      this.$router.push({ name: "edit-blog", query: { _id: this.blog._id } });
    },
    goToAgencyProfile() {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: this.blog.poster },
      });
    },
    onDeleteBlog() {
      this.deleteDialog = true;
    },
    onCloseDialog() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.loading = true;
      this.deleteBlog({ _id: this.blog._id })
        .then(() => {
          this.loading = false;
          this.$router.back();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response) {
            this.snackbar = true;
            this.errorMessage = error.response.data.message;
          } else {
            this.snackbar = true;
            this.errorMessage = error;
          }
        });
    },
    loadData() {
      this.getBlogDetails({ _id: this.$route.query._id })
        .then((res) => {
          this.blog = res;
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  watch: {
    "$route.query._id": function(newValue) {
      if (newValue) {
        this.blog = null;
        this.loadData();
      }
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
<style lang="scss">
.blog-detail-feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  justify-content: center;
  .feed-contents {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
  }
  h4 {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 24px;
  }
  .post-content p img {
    max-width: 100%;
  }
}
</style>
